import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import logo from '../logo.png';

const NavBar: React.FC<{}> = () => {

  let match = useRouteMatch();

  const [position, setPosition] = useState(0);

  const handleScroll = () => {
    setPosition(window.pageYOffset); // root?.scrollTop ?? 0;
  };

  useEffect(() => {
    console.log(match.url);
    setPosition(window.pageYOffset);
    window.addEventListener('scroll', handleScroll, { passive: true });
  }, []);

  return <nav className={classNames("mx-auto px-6 py-3 lg:fixed w-screen", { "bg-white shadow-lg": (position > 25 || match.url !== '/') })} style={{ "transition": "all ease .65s", zIndex: 1000 }}>
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="flex flex-row items-center justify-between flex-grow">
        <div className={classNames("text-4xl font-light font-serif text-gray-700 flex flex-row items-center space-x-4", { "lg:px-12 lg:py-24": (position <= 25 && match.url === '/') })} style={{ "transition": "all ease .35s" }}>
          <img src={logo} alt="Clan Crest" className={classNames("sm:h-24", { "lg:h-96": (position <= 25 && match.url === '/') })} style={{ "transition": "all ease .35s" }} />
          <span className={classNames({ "lg:text-6xl sm:text-5xl lg:text-white sm:text-black mb-24 brand-xl": (position <= 25 && match.url === '/') })}>
            Clan Donnachaidh Society in Ontario
          </span>
        </div>
        <Link to="/contact" className={classNames("font-light font-serif text-xl mr-8", { "hidden": position <= 25 })}>Contact Us</Link>
      </div>
    </div>
  </nav >;
}

export default NavBar;