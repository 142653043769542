import React from 'react';
import { BrowserRouter, Link, Route, Switch } from 'react-router-dom';
import './App.css';
import NavBar from './NavBar';
import SimpleNavBar from './SimpleNavBar';
import Home from '../Pages/Home';
import Contact from '../Pages/Contact';

function App() {
  return (
    <div className="App flex flex-col">
      <BrowserRouter>
        <Switch>
          <Route path="/contact">
            <SimpleNavBar />
            <div className="flex-grow">
              <Contact />
            </div>
          </Route>
          <Route path="/">
            <NavBar />
            <Home />
          </Route>
        </Switch>
        <div className="text-sm flex flex-row w-full justify-end space-x-4 p-4 text-gray-500">
          <Link className="" to="/contact">Contact</Link>
          <Link className="hidden" to="/privacy">Privacy</Link>
          <span>
            &copy; 2021 Clan Donnachaidh Society in Ontario
        </span>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
