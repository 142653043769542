import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import logo from '../logo.png';

const SimpleNavBar: React.FC<{}> = () => {

  return <nav className={classNames("mx-auto px-6 py-3 w-screen bg-white shadow-lg")}>
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="flex items-center justify-between">
        <div className={classNames("text-4xl font-light font-serif text-gray-700 flex flex-row items-center space-x-4")}>
          <img src={logo} alt="Clan Crest" className={classNames("sm:h-24")} />
          <span>
            Clan Donnachaidh Society in Ontario
          </span>
        </div>
      </div>
      <Link to="/contact" className="font-light font-serif text-xl mr-8">Contact Us</Link>
    </div>
  </nav>;
}

export default SimpleNavBar;