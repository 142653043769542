import React from 'react';
import Background from '../bruar_falls.jpg';
import terry from './terry-dobbie.jpg';

const Home: React.FC<{}> = () => {
    return <React.Fragment>
        <div className="w-full flex justify-between items-end bg-cover bg-center h-screen" style={{ "backgroundImage": `url(${Background})` }}>
            <div className="text-white p-4 w-full flex lg:flex-row sm:flex-col sm:space-y-4 justify-between" style={{ backgroundColor: "rgba(0, 0, 0, 0.8)" }}>
                <div className="space-y-2">
                    <p className="text-lg">
                        Bruar Falls, Perthsire, Scotland
                    </p>
                    <p>
                        Bruar Falls is a tourist attraction and a part of the clan's traditional lands.</p>
                    <p>
                        The poet Robert Burns was so taken back by the breathtaking sight of the falls, that he wrote
                        the following short poem, beseeching the 4th Duke of Atholl to plant trees on the
                        barren banks of the river.
                    </p>
                </div>
                <div>
                    <p className="italic">
                        Would then my noble master please<br />
                        To grant my highest wishes,<br />
                        He'll shade my banks wi' tow'ring trees,<br />
                        And bonnie spreading bushes.
                    </p>
                </div>
            </div>
        </div>
        <div className="bg-red-50 rounded-xl m-8 p-8 text-center hidden">
            <h1 className="text-2xl text-gray-800">
                Our response to COVID-19
            </h1>
            <p>
                In response to the SARS-CoV-2 (COVID-19) pandemic, CDSO has taken measures to help ensure the safety of
                it's staff and members.
            </p>
            <div className="pt-4 text-lg font-semibold">
                <a href="javascript: void()">Learn more &gt;</a>
            </div>
        </div>
        <div className="p-8">
            <h2 className="text-2xl pb-4">Who is Clan Donnachaidh</h2>
            <div className="flex flex-row">
                <div className="space-y-4 w-1/2 mr-16">
                    <p>
                        To understand who we are, is to know where we've come from.
                    </p>
                    <p>
                        Stout Duncan was our first recognized chief, and the second son of Angus MacDonald, Lord of the Isles.
                    </p>
                    <p>
                        Roberston's are lineal descendants of the Celtic Earl of Atholl, of whom their progenitor was King Duncan I. For those of
                        you who are fans of Shakespear, you'd be correct in guessing that King Duncan is indeed the same King Duncan of MacBeth fame.
                    </p>
                    <p>
                        Our first chief, Donnachaidh Reamhar, "Stout Duncan", was mearly a minor land-owner and leader of a kin-group around Dunkled, Perthshire.
                        Legend tells of how he was a fervent and dedicated supporter of Robert the Bruce. He stood beside Robert at the Battle of Bannockburn
                        in 1314 with his family and followers. It is around this time when they became known as Duncansons, or in Gaelic, Donnachaidh.
                    </p>
                    <p>
                        Duncan is believed to have been killed during the battle of Nevill's Cross, and was succeeded by Robert Riabhach. It is from this time
                        that the clan became known as Clan Robertson.
                    </p>
                </div>
                <div className="space-y-4 w-1/2">
                    <p>
                        Clan Donnachaidh fought in many conflicts and battles during the 14th and 15th centuries, and it was on August 15th, 1451, that Clan
                        Donnachaidh was awarded the crest badge of a right hand upholding an imperial crown by King James II, for the capture of King James'
                        father. The coat of arms also depicts a "savage man in chains" below the shield, paying homage to this event.
                    </p>
                    <p>
                        Around 1460, Robert Riabhach died from his wounds sustained in battle.
                    </p>
                    <p>
                        Clan Donnachaidh continue to fight for freedom well in to the 17th century civil war, as well as in the 18th century Jacobite uprisings.
                    </p>
                    <p>
                        To this day, people associated with the clan continue to make sacrifices to help support their fellow man, contribute to science, technology,
                        journalism and more. Our rich and storied history is proud and wonderful.
                    </p>
                </div>
            </div>
            <h2 className="text-2xl pb-4 mt-6">Must see attractions in Scotland</h2>
            <ul className="space-y-6">
                <li>
                    <span className="font-semibold">Dunalistair Castle</span>
                    <p>
                        The seat of the chiefs, this castle was eventually replaced by Dall House.
                    </p>
                </li>
                <li>
                    <span className="font-semibold">Lude Castle</span>
                    <p>
                        This castle was raized by Oliver Cromwell in 1650, in relatiation for Alexander Roberston fighting on the side of James Graham
                        at the Battle of Tippermur in 1644.
                    </p>
                </li>
                <li>
                    <span className="font-semibold">Auchleeks Castle</span>
                    <p>
                        Held by Clan Donnachaidh from the 1530's and later replaced by a mansion in 1962. The garden is occaisionally open to the public.
                    </p>
                </li>
                <li>
                    <span className="font-semibold">Eilean nam Faolaig</span>
                    <p>
                        Near Kinloch Rannoch, Perthshire, the site of a castle on an island that was held by the Roberstons of Struan and used both as
                        a refuge and a prison.
                    </p>
                </li>
                <li>
                    <span className="font-semibold">Bruar Falls</span>
                    <p>
                        A gorgeous natural heritage site, favoured by all, including Robert Burns himself. Be prepared for a real hike.
                    </p>
                </li>
            </ul>
        </div>
        <div className="flex flex-row bg-gray-50 space-x-8 p-8">
            <div className="flex flex-col lg:w-1/2">
                <figure className="lg:flex bg-white rounded-xl overflow-hidden p-8 lg:p-0 shadow-md h-auto">
                    <div className="relative overflow-hidden flex-none lg:rounded-none rounded-full w-32 h-32 lg:w-48 lg:h-auto mx-auto">
                        <img src={terry} alt="" className="lg:absolute lg:max-w-none lg:object-cover lg:w-full lg:h-full" />
                    </div>
                    <div className="p-6 md:space-y-8">
                        <blockquote className="text-md font-normal space-y-2">
                            <p>
                                &ldquo;As president of Clan Donnachaidh Society in Ontario, it is with great pride that I welcome everyone here.
                            </p>
                            <p>
                                Clan Donnachaidh is one of the oldest clans in existence today. We fought along side Bonnie Prince Charlie
                                in the Battle of Culloden, and one of the only clans to fight in each of the Jacobite uprisings.
                            </p>
                            <p>
                                My goal is to continue with the legacy of our forefathers and pass down the history of our great clan,
                                so that it may never be forgotten of the lives that were laid down by our Scottish ancestors, then and
                                now, so we can live free of persecution in Scotland and around the world.
                            </p>
                            <p>
                                We encourage you to join us, and share in the pride of our ancestry.&rdquo;
                            </p>
                        </blockquote>
                        <figcaption className="font-normal text-lg">
                            <div className="text-blue-500">
                                Terry Dobbie
                            </div>
                            <div className="text-gray-500">
                                President, Clan Donnachaidh Society in Ontario
                            </div>
                        </figcaption>
                    </div>
                </figure>
            </div>
            <div className="flex flex-col lg:w-1/4">
                <div className="bg-white rounded-xl w-full shadow-md overflow-hidden">
                    <div className="p-8 space-y-4">
                        <h2 className="text-2xl">2021 Virtual Ceilidh</h2>
                        <p>
                            For the second year in a row, the Fergus Scottish Festival and Highland Games
                            went virtual during the ongoing pandemic. With that, Clan Donnachaidh continued
                            to support the games through their virtual ceilidh, hoping to spread information
                            about our clan's history to the many virtual visitors.
                        </p>
                    </div>
                    <div className="flex flex-row items-center">
                        <div className="mx-auto">
                            <a href="https://youtu.be/sYSXKDvgJLE" target="_blank" className="text-blue-500">
                                <img className="object-cover" src="https://img.youtube.com/vi/sYSXKDvgJLE/0.jpg" />
                                <div className="p-2">Click here or on the image above to view the video.</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col lg:w-1/4">
                <div className="bg-white rounded-xl w-full shadow-md overflow-hidden">
                    <div className="p-8 space-y-4">
                        <h2 className="text-2xl">2022 Renewals</h2>
                        <p>
                            It's that time of year again. We have resumed taking applications for
                            new memberships and renewals for the 2022 year. Please fill out and return
                            the following form as soon as possible!
                        </p>
                        <a href="static/media/CDSO_Application_2021.pdf" className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 border-b-4 border-blue-700 hover:border-blue-600 rounded space-x-4 inline-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                </svg>
                                <div className="flex flex-col">
                                    <span className="font-semibold">Download Renewal Form</span>
                                    <span className="font-normal">CDSO Application 2021.pdf</span>
                                </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}

export default Home;