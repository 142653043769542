import React from 'react';
import { Link } from 'react-router-dom';

const Contact: React.FC<{}> = () => {
    return <div className="p-8 space-y-4">
        <Link to="/" className="text-blue-500">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" style={{ height: 24, width: 24, display: 'inline-block', marginBottom: 4 }}>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            Home
        </Link>
        <h2 className="text-2xl">How to Reach Us</h2>
        <p>
            We like hearing from our members and prospective members, so you have any questions, comments or concerns, feel
            free to reach out to us via the methods below.
        </p>
        <p>
            Please note, we cannot help with genealogical research for your family tree; there are many different resources
            available, both free and paid on the internet, as well as your local library or public archives.
        </p>
        <h3 className="text-xl">Membership</h3>
        <p className="text-gray-600">
            Do you need help with your membership application or renewals?<br />
            Have questions related to joining or renewing?<br />
            Feel free to reach out to us below.
        </p>
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style={{ height: 20, width: 20, display: 'inline-block' }}>
                <path fillRule="evenodd" d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z" clipRule="evenodd" />
            </svg>
            <a className="text-blue-500" href="mailto:membership@donnachaidhontario.ca">membership@donnachaidhontario.ca</a>
        </div>
        <h3 className="text-xl">Information</h3>
        <p className="text-gray-600">
            Do you have questions about the clan society?<br />
            Or want to let us know how we're doing, we'd like to hear from you.
        </p>
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style={{ height: 20, width: 20, display: 'inline-block' }}>
                <path fillRule="evenodd" d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z" clipRule="evenodd" />
            </svg>
            <a className="text-blue-500" href="mailto:info@donnachaidhontario.ca">info@donnachaidhontario.ca</a>
        </div>
        <h3 className="text-xl">Website Problems?</h3>
        <p className="text-gray-600">
            Are you having problems viewing our website? Reach out to our webmaster easily!
        </p>
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" style={{ height: 20, width: 20, display: 'inline-block' }}>
                <path fillRule="evenodd" d="M14.243 5.757a6 6 0 10-.986 9.284 1 1 0 111.087 1.678A8 8 0 1118 10a3 3 0 01-4.8 2.401A4 4 0 1114 10a1 1 0 102 0c0-1.537-.586-3.07-1.757-4.243zM12 10a2 2 0 10-4 0 2 2 0 004 0z" clipRule="evenodd" />
            </svg>
            <a className="text-blue-500" href="mailto:webmaster@donnachaidhontario.ca">webmaster@donnachaidhontario.ca</a>
        </div>
    </div>;
}

export default Contact;